import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
} from 'react';

export function usePrevious<T>(value: T): T | undefined;
export function usePrevious<T, F extends T>(value: T, initialValue: F): F;
export function usePrevious<T, F extends T>(value: T, initialValue?: F) {
  const ref = useRef<T | undefined>(initialValue);

  useEffect(() => {
    if (value !== ref.current) ref.current = value;
  }, [value]);

  return ref.current;
}

export type DebouncerType = [(callback: DebouncerCallback) => void, () => void];

type DebouncerCallback = () => unknown | Promise<unknown>;

export function useDebouncer(delay: number): DebouncerType {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const debounce = useCallback(
    (callback: DebouncerCallback) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(callback, delay);
    },
    [delay]
  );

  const clearDebounce = useCallback(() => {
    if (timeout.current) clearTimeout(timeout.current);
  }, []);

  return [debounce, clearDebounce];
}

export function useDidMount() {
  const didMountRef = useRef(false);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  return didMountRef.current;
}

export function useEffectDebugger(
  effectHook: EffectCallback,
  dependencies: DependencyList,
  dependencyNames: string[] = []
) {
  const previousDeps: DependencyList | undefined = usePrevious(dependencies);

  const changedDeps = dependencies.reduce<Record<string, unknown>>(
    (accum, dependency, index) => {
      if (previousDeps && dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        };
      }

      return accum;
    },
    {}
  );

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  } else {
    console.log('[use-effect-debugger] no detected changes ', {
      current: dependencies,
      previous: previousDeps,
    });
  }

  useEffect(effectHook, [...dependencies, effectHook]);
}
