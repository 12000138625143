import { useSelector } from 'react-redux';
import { selectEstimateAccessorials } from '../../lib/reducers/estimateSlice';
import { Accessorial } from '../../lib/actions/AccessorialAction';
import { Checkbox, Icon } from '@blueprintjs/core';
import FieldError from '../form/FieldError';
import { useField } from 'formik';
import { MatchAccessorialData } from '../../lib/actions/MatchAction';
import { ChangeEvent } from 'react';
import { Col, Row } from 'react-flexbox-grid';

type AccessorialOptionProps = {
  accessorial: Accessorial;
};

function AccessorialOption({ accessorial }: AccessorialOptionProps) {
  const name = `accessorials`;

  const [
    { value: matchAccessorials, onChange, ...props },
    ,
    { setValue, setTouched },
  ] = useField<MatchAccessorialData[] | undefined>(name);

  const checked = matchAccessorials?.some(ma => ma.key === accessorial.key);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const accessorials = matchAccessorials || [];
    const value = event.currentTarget.checked
      ? [...accessorials, { key: accessorial.key }]
      : accessorials.filter(ma => ma.key !== accessorial.key);

    setTouched(true, false);
    setValue(value);
  };

  return (
    <Col md={4} sm={6} xs={12} className='divider--vertical'>
      <Checkbox checked={checked} onChange={handleChange} {...props}>
        <Icon icon='box' className='u-push__right--xs' />
        <strong>{accessorial.name}</strong>
      </Checkbox>
      <span style={{ marginTop: 4, display: 'block' }}>
        {accessorial.description}
      </span>
    </Col>
  );
}

export default function AccessorialOptions() {
  const accessorials = useSelector(selectEstimateAccessorials);

  return (
    <Row>
      {accessorials.map(accessorial => (
        <AccessorialOption accessorial={accessorial} key={accessorial.id} />
      ))}
      <FieldError name='accessorials' />
    </Row>
  );
}
